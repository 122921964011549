import requestHandler from '@/utils/requestHandler'
import HTTP from '@/utils/http'

export default {
  namespaced: true,
  state: {
    PaymentCalendar: {}
  },
  getters: {
    PaymentCalendar: state => state.PaymentCalendar
  },
  actions: {
    async getCalendarData({ commit, dispatch }, params) {
      const loadingName = 'getCalendarData'
      commit('RESET_CALENDAR_DATA')
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get('Payment/calender', {
            params
          })
        },
        success: result => {
          commit('SET_CALENDAR_DATA', result.data)
          return result
        }
      })
      return results
    }
  },

  mutations: {
    RESET_CALENDAR_DATA(state) {
      state.PaymentCalendar = {}
    },
    SET_CALENDAR_DATA(state, list) {
      state.PaymentCalendar = list
    }
  }
}
