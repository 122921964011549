import requestHandler from '@/utils/requestHandler'
import HTTP from '@/utils/http'
import moment from 'moment'
import { getPaginationNumber } from '@/utils/pagination'

export default {
  namespaced: true,
  state: {
    Payments: [],
    totalPayment: null,
    totalPurchase: null,
    debt: null,
    Page: {
      number: 1,
      size: 20,
      total: 0
    },
    isShowDelete: false,
    pageHeaderLinks: [
      'Payment',
      'PaymentCalendar',
      'PaymentType'
    ]
  },

  getters: {
    pageHeaderLinks: state => state.pageHeaderLinks,
    payments: state => state.Payments,
    isEmptyPayments: state => state.Payments.length === 0,
    page: state => ({
      ...state.Page,
      last: Math.ceil(state.Page.total / state.Page.size)
    }),
    isShowDelete: state => state.isShowDelete,
    totalPayment: state => state.totalPayment,
    totalPurchase: state => state.totalPurchase,
    debt: state => state.debt
  },

  actions: {
    async getPaymentsList({ commit, state }, {page = 1, supplierList = [], paymentOptionList = [], startDate, endDate, pageSize}) {
      const PAGE_NUMBER = getPaginationNumber({ selectedPage: page, currentPage: state.Page.number })

      const params = {
        PageSize: pageSize,
        PageNumber: PAGE_NUMBER,
        supplierIdList: supplierList,
        paymentOptionList: paymentOptionList,
        startDate: moment(startDate).format('YYYY-MM-DD'),
        endDate: moment(endDate).format('YYYY-MM-DD'),
        isDeleted: state.isShowDelete
      }

      const results = await requestHandler({
        title: 'getPaymentsList',
        methodName: 'getPaymentsList',
        method: () => {
          return HTTP.post('Payment/all', params)
        },
        success: result => {
          commit('SET_PAYMENT_LIST', result.data)
          return result
        }
      })
      return results
    },

    async getPayment({ commit, dispatch }, Id) {
      const loadingName = 'Payments/createPayment'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get('Payment', {
            params: { Id }
          })
        },
        success: result => result
      })
      return results
    },

    async createPayment({ commit, dispatch }, item) {
      const loadingName = 'Payments/createPayment'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.post('Payment', item)
        },
        success: result => result
      })
      return results
    },

    async updatePayment({ commit, dispatch }, item) {
      const loadingName = 'Payments/updatePayment'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.put('Payment', item)
        },
        success: result => result
      })
      return results
    },

    async deletePayment({ state, commit, dispatch }, Id) {
      const loadingName = 'Payments/deletePayment'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.delete('Payment', {
            params: { Id }
          })
        },
        success: result => result
      })
      return results
    }
  },
  mutations: {
    SET_PAYMENT_LIST(state, data) {
      state.Payments = data.paymentList.map(item => ({
        paymentOption: item.paymentOptionName ? item.paymentOptionName : '-',
        rowTextClass: item.isDeleted ? 'c-warning' : '',
        ...item
      })
    )
      state.Page.number = data.pageNumber
      state.Page.total = data.totalCount
      state.Page.size = data.pageSize
      state.totalPayment = data.totalPayment
      state.totalPurchase = data.totalPurchase
      state.debt = data.debt
    },
    TOGGLE_IS_SHOW(state) {
      state.isShowDelete = !state.isShowDelete
    },
    PASSIVE_IS_SHOW(state) {
      state.isShowDelete = false
    },
    ACTIVE_IS_SHOW(state) {
      state.isShowDelete = true
    }
  }
}
