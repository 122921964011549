import BaseClass from '@/utils/baseclass'

export class PaymentTypes extends BaseClass {
  addItem(item) {
    let newItem = new PaymentType(item)
    this.list.push(newItem)
  }
  updateItem(item) {
    let findItem = this.findItem(item.id)
    if (findItem) {
      findItem.id = item.id
      findItem.name = item.name
    }
  }
  }
class PaymentType {
  constructor(item) {
    this.id = item.id
    this.name = item.name
  }
  }
