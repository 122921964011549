import requestHandler from '@/utils/requestHandler'
import HTTP from '@/utils/http'
import { PaymentTypes } from '../model/payment-type.js'

export default {
  namespaced: true,

  state: {
    paymentTypes: new PaymentTypes()
  },

  getters: {
    paymentType_List: state => state.paymentTypes.list,
    paymentType_IsEmpty: state => state.paymentTypes.length === 0
  },

  actions: {
    async get_PAYMENTTYPE_LIST({ commit }) {
      const results = await requestHandler({
        title: 'get_PAYMENTTYPE_LIST',
        methodName: 'get_PAYMENTTYPE_LIST',
        method: () => {
          return HTTP.get('PaymentOption/all?PageSize=99999&PageNumber=1')
        },
        success: result => {
          commit('UPDATE_PAYMENTTYPE_LIST', result.data.paymentTypeList)
          return result
        }
      })
      return results
    },

    async get_PAYMENTTYPE_ITEM({ commit }, Id) {
      const results = await requestHandler({
        title: 'get_PAYMENTTYPE_ITEM',
        methodName: 'get_PAYMENTTYPE_ITEM',
        method: () => {
          return HTTP.get('PaymentOption/', {
            params: { Id }
          })
        },
        success: result => result
      })
      return results
    },

    async create_PAYMENTTYPE_ITEM({ commit }, item) {
      const results = await requestHandler({
        title: 'create_PAYMENTTYPE_ITEM',
        methodName: 'create_PAYMENTTYPE_ITEM',
        method: () => {
          return HTTP.post('PaymentOption/', item)
        },
        success: result => result
      })
      return results
    },

    async update_PAYMENTTYPE_ITEM({ commit, dispatch }, item) {
      const results = await requestHandler({
        title: 'update_PAYMENTTYPE_ITEM',
        methodName: 'update_PAYMENTTYPE_ITEM',
        method: () => {
          return HTTP.put('PaymentOption/', item)
        },
        success: result => result
      })
      return results
    },

    async delete_PAYMENTTYPE_ITEM({ commit, dispatch }, Id) {
      const results = await requestHandler({
        title: 'delete_PAYMENTTYPE_ITEM',
        methodName: 'delete_PAYMENTTYPE_ITEM',
        method: () => {
          return HTTP.delete('PaymentOption/', {
            params: { Id }
          })
        },
        success: result => result
      })
      return results
    },
    async CheckPaymentTypeName({ commit }, params) {
      const results = await requestHandler({
        title: 'PaymentType/CheckPaymentTypeName',
        methodName: 'PaymentType/CheckPaymentTypeName',
        method: () => {
          return HTTP.get('PaymentOption/check/name', {
            params
          })
        },
        success: result => result
      })
      return results
    }
  },

  mutations: {
    RESET(state) {
      state.paymentTypes = new PaymentTypes()
    },

    UPDATE_PAYMENTTYPE_LIST(state, list) {
      state.paymentTypes.addItems(list)
    }
  }
}
