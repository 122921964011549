var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("DeleteAlert", {
    attrs: {
      item: _vm.item,
      fields: _vm.fields,
      tableName: _vm.$t("PaymentType.remove_TableName"),
      loading: "Supplier/deleteSupplier",
    },
    on: { delegateOnAlertRemoveOk: _vm.itemRemove },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }