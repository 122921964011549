<template lang="pug">
DeleteAlert(
  :item="item",
  :fields="fields",
  :tableName="$t('PaymentType.remove_TableName')",
  loading="Supplier/deleteSupplier",
  @delegateOnAlertRemoveOk="itemRemove")
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'PaymentTypeRemove',
  data () {
    return {
      fields: [
        {
          name: 'name',
          is_visible: true,
          short_title: this.$t('PaymentType.popupForm_Field_Name'),
          title: this.$t('PaymentType.popupForm_Field_Name')
        }
      ],
      item: {}
    }
  },
  async mounted () {
    const res = await this.get_PAYMENTTYPE_ITEM(this.$route.params.id)
    if (res) {
      this.item = res.data.paymentOption
    }
  },
  methods: {
    ...mapActions('Notify', [
      'notifyShow'
    ]),
    ...mapActions('PaymentTypes', [
      'get_PAYMENTTYPE_LIST',
      'get_PAYMENTTYPE_ITEM',
      'delete_PAYMENTTYPE_ITEM'
    ]),

    close () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    itemRemove () {
      this.delete_PAYMENTTYPE_ITEM(this.item.id).then(res => {
        if (res) {
          const message = this.$t('Global.notification_Deleted', { name: this.item.name })
          this.notifyShow({ message })
          this.get_PAYMENTTYPE_LIST()
          this.close()
        }
      })
    }

  }
}
</script>

<style lang="scss">
</style>
