<template lang="pug">
Popup
    template(slot="title")
      div(v-if="isEdit") {{ $t('Payments.formEditTitle') }}
      div(v-else) {{ $t('Payments.formAddTitle') }}

    template(slot="content")

     form.Form(
     id="form-payment"
     @submit.prevent="onSubmitPaymentForm")

        .Form-item.required
          label.Form-item-label {{ $t('Payments.formSupplierName') }}
          .control
            custom-search(
            name="supplierName"
            v-model="form.supplier"
            :data-vv-as="$t('Payments.formSupplierName')"
            v-validate="'required'"
            :inputClass="{ 'is-danger': veeErrors.has('supplierName') }"
            ref="firstField"
            :on-search="getOptions"
            :options="selectOptions"
            label="name"
            :loading="isSearchSupplier"
            :placeholder="$t('Payments.formSupplierName')"
            :dontShowDropdown="dontShowDropdownSupplierDropdown")
              template(slot="no-options") {{ $t('Payments.formSupplierNameNoResult') }}

            showValidateError(
              v-show="veeErrors.has('supplierName')"
              :errorName="veeErrors.first('supplierName')"
            )

        .Form-item.required
          label.Form-item-label {{ $t('Payments.formPaymentDate') }}
          .control.form-item-select
            CustomDatepicker(
              id="payment-form-date"
              name="paymentDate",
              :data-vv-as="$t('Payments.formPaymentDate')",
              v-model="form.paymentDate",
              :class="{ 'is-danger': veeErrors.has('paymentDate') }",
              :disabledStartDate="disableDate",
              v-validate="'required'"
              pickerType="manuel",
              inputIconRight="icon-datepicker-big"
              inputIcon="")

            showValidateError(
              v-show="veeErrors.has('paymentDate')"
              :errorName="veeErrors.first('paymentDate')"
            )

        .Form-item
          label.Form-item-label
            | {{ $t('Payments.formPaymentType') }}
          .control.form-item-select
            Icon(name="icon-down-arrow")

            customSelectInput(
              name="paymentTypeName"
              id="select-payments-payment-type"
              :optionData="paymentTypeList"
              v-model="form.paymentOptionId"
              optionIdName="option-payments-payment-type"
            )

        .Form-item.required
          label.Form-item-label {{ $t('Payments.formAmount') }}
          .control
            currency-input.txt(
            name="amount"
            :class="{ 'is-danger': veeErrors.has('amount') }"
            :data-vv-as="$t('Payments.formAmount')"
            v-model="form.amount"
            v-validate="'required|greater_than:0'")

            showValidateError(
              v-show="veeErrors.has('amount')"
              :errorName="veeErrors.first('amount')"
            )

        .Form-item
          label.Form-item-label {{ $t('Payments.formPaymentDescription') }}
          .control
            customTextareaInput(
              name="description"
              id="payment-textarea-description"
              v-model="form.description"
              v-validate="'max:512'"
              :data-vv-as="$t('Payments.formPaymentDescription')"
              :error="veeErrors.has('description')"
            )

            showValidateError(
              v-show="veeErrors.has('description')"
              :errorName="veeErrors.first('description')"
            )

    template(slot="footer")
      Button.uppercase(
      primary
      size="large"
      variant="full"
      id="btn-payment-popup-submit"
      type="submit",
      form="form-payment",
      :disabled="isLoading")
        span(v-show="!isLoading")
          template(v-if="isEdit") {{ $t('Payments.formEditTitle') }}
          template(v-else) {{ $t('Payments.formAddTitle') }}
        Loading(theme="disable", v-show="isLoading")

</template>
<script>
import { mapActions } from 'vuex'
import moment from 'moment'
import { omit, getObjectValuesByKey, vueWaitLoader } from '@/utils/baseOperations'

export default {
  name: 'PaymentForm',
  props: {
    status: {
      type: String,
      default: 'new'
    }
  },

  data () {
    return {
      disableDate: {
        from: new Date()
      },
      paymentTypeList: [],
      selectOptions: [],
      form: {
        supplier: null,
        paymentDate: new Date(),
        paymentOptionId: null,
        amount: null,
        description: null
      },
      dontShowDropdownSupplierDropdown: true
    }
  },
  computed: {
    isEdit () {
      return this.status === 'edit'
    },

    newItem () {
      return {
        ...this.form,
        supplierId: this.form.supplier.id,
        paymentDate: moment(this.form.paymentDate).format('YYYY-MM-DD')
      }
    },
    isLoading () {
      return this.$wait.is(['Payments/createPayment', 'Payments/updatePayment', 'submitForm'])
    },

    isSearchSupplier () {
      return this.$wait.is('searchSupplier')
    }
  },

  methods: {
    ...mapActions('Supplier', [
      'searchSupplier'
    ]),

    ...mapActions('Despatch', [
      'get_PaymentList'
    ]),

    ...mapActions('Notify', [
      'notifyShow'
    ]),

    ...mapActions('Payments', [
      'getPayment',
      'createPayment',
      'updatePayment',
      'getPaymentsList'
    ]),
    close () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    focusFirstInput () {
      this.$refs.firstField.$refs.search.focus()
    },

    getOptions (search) {
      if (this.dontShowDropdownSupplierDropdown) this.dontShowDropdownSupplierDropdown = false
      this.searchSupplier(search)
        .then(res => {
          if (res.status === 200) {
            this.selectOptions = res.data.items
          }
        })
    },
    async submitNew () {
      const payload = omit(this.newItem, ['supplier'])
      const res = await this.createPayment(payload)
      if (res) {
        const message = this.$t('Payments.successCreateMessage')
        this.notifyShow({message})
        const result = this.getPaymentsList({
          page: 1,
          supplierList: this.$route.params.filterData.supplierList,
          paymentOptionList: this.$route.params.filterData.paymentOptionList,
          startDate: this.$route.params.filterData.startDate,
          endDate: this.$route.params.filterData.endDate
        })
        if (result) this.close()
      }
    },

    async submitEdit () {
      const payload = getObjectValuesByKey(this.newItem, ['id', 'paymentOptionId', 'paymentDate', 'amount', 'description'])
      const res = await this.updatePayment(payload)
      if (res) {
        const message = this.$t('Payments.successUpdateMessage')
        this.notifyShow({message})
        this.getPaymentsList({
          page: 1,
          supplierList: this.$route.params.filterData.supplierList,
          paymentOptionList: this.$route.params.filterData.paymentOptionList,
          startDate: this.$route.params.filterData.startDate,
          endDate: this.$route.params.filterData.endDate
        })
        this.close()
      }
    },
    onSubmitPaymentForm: vueWaitLoader(async function () {
      await this.$validator.validateAll().then(async result => {
        if (!result) return

        if (this.isEdit) {
          await this.submitEdit()
        } else await this.submitNew()
      })
    }, 'submitForm')
  },

  async mounted () {
    this.focusFirstInput()
    const res = await this.get_PaymentList()
    if (res) {
      this.paymentTypeList = res.data.paymentTypeList
    }
    if (this.isEdit) {
      const result = await this.getPayment(this.$route.params.id)
      if (result) {
        this.form = {
          ...result.data.payment,
          supplier: {
            id: result.data.payment.supplierId,
            name: result.data.payment.supplierName
          }
        }
      }
    }
  }
}
</script>
