import Index from '../'
import Calendar from '../payment-calendar'
import PaymentType from '../payment-type'
import PaymentForm from '../popup/payment-form'
import PaymentRemove from '../popup/payment-remove'
import PaymentTypeForm from '../popup/types-form'
import PaymentTypeRemove from '../popup/types-remove'

export default [
  {
    path: '/payments',
    name: 'Payment',
    component: Index,
    meta: {
      main_menu: true,
      slug: 'payments',
      category: 'Buy',
      permissionKey: 'PaymentMenu',
      page: 'payments'
    },
    children: [
      {
        path: 'item/:filterData/new',
        name: 'PaymentNew',
        component: PaymentForm
      },
      {
        path: 'item/:id/:filterData/edit',
        name: 'PaymentEdit',
        component: PaymentForm,
        props: {
          status: 'edit'
        }
      },
      {
        path: 'item/:id/:filterData/remove',
        name: 'PaymentsRemove',
        component: PaymentRemove
      }
    ]
  },
  {
    path: '/payments/calendar',
    name: 'PaymentCalendar',
    component: Calendar,
    meta: {
      page: 'payments'
    }
  },
  {
    path: '/payments/types',
    name: 'PaymentType',
    component: PaymentType,
    meta: {
      page: 'payments'
    },
    children: [
      {
        path: 'new',
        name: 'PaymentTypeNew',
        component: PaymentTypeForm
      },
      {
        path: 'item/:id/edit',
        name: 'PaymentTypeEdit',
        component: PaymentTypeForm,
        props: {
          status: 'edit'
        }
      },
      {
        path: 'item/:id/remove',
        name: 'PaymentTypeRemove',
        component: PaymentTypeRemove
      }
    ]
  }
]
