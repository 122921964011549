<template lang="pug">
Popup

    template(slot="title")
      template(v-if="isEdit") {{ $t('PaymentType.popupForm_Title_Edit') }}
      template(v-else) {{ $t('PaymentType.popupForm_Title_Create') }}

    template(slot="content")
      form.Form(
      id="form-payment-type"
      @submit.prevent="onSubmitPaymentTypeForm")

        div.Form-item.required
          label.Form-item-label {{ $t('PaymentType.popupForm_Field_Name') }}
          .control
            customTextInput(
              name="name"
              ref="firstField"
              id="input-payment-type-popup-field-name"
              :data-vv-as="$t('PaymentType.popupForm_Field_Name')"
              :error="veeErrors.has('name')"
              v-validate="'required|max:128|verify_name'"
              v-model="item.name"
              :isDelayInput="true"
            )

            Button(
            variant="icon loading"
            id="btn-payment-type-popup-loading-name"
            type="button",
            tabindex="-1",
            v-show="isPendingName")
              Loading(theme="disable")
            Button(
            variant="icon check"
            id="btn-payment-type-popup-ok-name"
            type="button",
            tabindex="-1",
            iconName="icon-check"
            iconClass="icon-check"
            :justIcon="true"
            v-show="isValidateName")
            
            showValidateError(
              v-show="veeErrors.has('name')"
              :errorName="veeErrors.first('name')"
            )

    template(slot="footer")
      template(v-if="isEdit")

        Button(
        primary
        size="large"
        variant="full"
        id="btn-payment-type-popup-submit-new"
        type="submit"
        form="form-payment-type"
        :disabled="isLoading")
          span(v-show="!isLoading") {{ $t('PaymentType.popupForm_Button_Edit') }}
          Loading(theme="disable", v-show="isLoading")

      template(v-else)

        Button(
        primary
        size="large"
        variant="full"
        id="btn-payment-type-popup-submit-new"
        type="submit"
        form="form-payment-type"
        :disabled="isLoading")
          span(v-show="!isLoading") {{ $t('PaymentType.popupForm_Button_Create') }}
          Loading(theme="disable", v-show="isLoading")

</template>

<script>
import { mapFields, Validator } from 'vee-validate'
import { mapActions } from 'vuex'
import { omit } from '@/utils/baseOperations'

export default {
  name: 'PaymentTypeForm',

  props: {
    status: {
      type: String,
      default: 'new'
    }
  },

  data () {
    return {
      item: {
        id: null,
        name: null
      }
    }
  },

  created () {
    let self = this
    Validator.extend('verify_name', {
      // Custom validation message
      getMessage: () => self.$t('PaymentType.Check_Name_Payment_Type'),
      // Custom validation rule
      validate: (value) => new Promise(resolve => {
        let params = {
          Name: value,
          Id: self.isEdit ? self.item.id : null
        }
        self.CheckPaymentTypeName(params)
          .then(res => {
            if (res) {
              resolve({
                valid: res.data.isAvailable
              })
            } else {
              resolve({
                valid: false
              })
            }
          })
      })
    })
  },

  async mounted () {
    if (this.isEdit) this.init()
    this.formFocus()
  },

  computed: {
    ...mapFields({
      flagsName: 'name'
    }),

    isLoading () {
      return this.$wait.is(['update_PAYMENTTYPE_ITEM']) || this.$wait.is(['create_PAYMENTTYPE_ITEM'])
    },

    isEdit () {
      return this.status === 'edit'
    },

    isPendingName () {
      return this.flagsName.pending
    },

    isValidateName () {
      if (this.flagsName.required) {
        return this.flagsName.valid && !this.isPendingName
      } else {
        return this.flagsName.valid && this.item.name !== '' && this.item.name !== null && !this.isPendingName
      }
    }
  },

  methods: {
    ...mapActions('Notify', [
      'notifyShow'
    ]),

    ...mapActions('PaymentTypes', [
      'get_PAYMENTTYPE_LIST',
      'get_PAYMENTTYPE_ITEM',
      'create_PAYMENTTYPE_ITEM',
      'update_PAYMENTTYPE_ITEM',
      'CheckPaymentTypeName'
    ]),

    async init () {
      let itemId = this.$route.params.id
      await this.get_PAYMENTTYPE_ITEM(itemId).then(res => {
        if (res.status === 200) {
          this.item = res.data.paymentOption
        }
      })
    },

    close () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    formFocus () {
      this.$refs.firstField.focusCustomInput()
    },

    onSubmitPaymentTypeForm () {
      this.$validator.validateAll().then(result => {
        // form error
        if (!result) return

        if (this.isEdit) {
          return this.submitEdit()
        }
        return this.submitNew()
      })
    },

    submitNew () {
      let item = omit(this.item, ['id'])
      this.create_PAYMENTTYPE_ITEM(item).then(res => {
        if (res) {
          const message = this.$t('Global.notification_Created', { name: item.name })
          this.notifyShow({ message })
          this.get_PAYMENTTYPE_LIST()
          if (this.saveAndNew) {
            this.item = {
              id: null,
              name: null
            }
            this.formFocus()
            this.$nextTick(() => {
              this.$validator.reset()
            })
          } else {
            this.close()
          }
        }
      })
    },

    submitEdit () {
      this.update_PAYMENTTYPE_ITEM(this.item).then(res => {
        if (res.status === 200) {
          const message = this.$t('Global.notification_Updated', { name: this.item.name })
          this.notifyShow({ message })
          this.get_PAYMENTTYPE_LIST()
          this.close()
        }
      })
    }
  }
}
</script>
