<template lang="pug">
DeleteAlert(
  :item="item",
  :fields="fields",
  :tableName="$t('Payments.removePaymetTitle')",
  loading="Payments/deletePayment",
  @delegateOnAlertRemoveOk="itemRemove")
</template>

<script>
import { mapActions } from 'vuex'
import { vueWaitLoader } from '@/utils/baseOperations'

export default {
  name: 'PaymentRemove',

  data () {
    return {
      fields: [
        {
          name: 'supplierName',
          is_visible: true,
          short_title: this.$t('Payments.dataFieldSupplierName'),
          title: this.$t('Payments.dataFieldSupplierName')
        },
        {
          name: 'formattedPaymentDate',
          is_visible: true,
          short_title: this.$t('Payments.dataFieldPaymentDate'),
          title: this.$t('Payments.dataFieldPaymentDate')
        },
        {
          name: 'amount',
          is_visible: true,
          short_title: this.$t('Payments.dataFieldAmount'),
          title: this.$t('Payments.dataFieldAmount')
        }
      ],
      item: {}
    }
  },

  async mounted () {
    const res = await this.getPayment(this.$route.params.id)
    if (res) {
      this.item = {
        ...res.data.payment,
        formattedPaymentDate: new Date(res.data.payment.paymentDate).toLocaleDateString('tr')
      }
    }
  },

  methods: {
    ...mapActions('Notify', [
      'notifyShow'
    ]),

    ...mapActions('Payments', [
      'getPaymentsList',
      'deletePayment',
      'getPayment'
    ]),

    close () {
      this.$router.go(-1)
    },

    itemRemove: vueWaitLoader(async function () {
      await this.deletePayment(this.item.id).then(res => {
        if (res) {
          const message = this.$t('Payments.removePaymentMessage', {
            name: this.item.supplierName
          })
          this.notifyShow({message})
          this.getPaymentsList({
            page: 1,
            supplierList: this.$route.params.filterData.supplierList,
            paymentOptionList: this.$route.params.filterData.paymentOptionList,
            startDate: this.$route.params.filterData.startDate,
            endDate: this.$route.params.filterData.endDate
          })
            .then(res => {
              if (res) this.close()
            })
        }
      })
    }, 'deleteForm')
  }
}
</script>
